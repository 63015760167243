.battery-na {
    display: flex;
    align-items: center;
    padding: 0 13px 0 4px;
}
.icon-container-na {
    width: 27px;
    height: 12px;
}
.outer-rect-na {
    display: flex;
    align-items: center;
    width: 21px;
    height: 13px;
    padding: 0 3%;
    border-radius: 4px;
    overflow: auto;
}

.inner-rect-na {
    height: 9px;
    border-radius: 3px;
}

.semi-circle-na {
    margin-top: 0.3px;
    margin-left: -6px;
    width: 1.5px;
    height: 4px;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
}